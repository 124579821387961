// src/components/Footer.jsx
import React, { useState, useEffect } from "react";
import {
  FaCopy,
  FaDownload,
  FaTelegramPlane,
  FaYoutubeSquare,
  FaSnapchatSquare,
} from "react-icons/fa";
import "./Footer.css";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "sonner";
import { FaSquareXTwitter, FaSquareInstagram } from "react-icons/fa6";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const cryptoAddresses = [
    {
      name: "Ethereum",
      icon: "/assets/ethereum.png",
      address: "0x8E441dac8EB44DDF8FC59A5aCB84954Fd0Ece61d",
    },
    {
      name: "USDT",
      icon: "/assets/usdt.png",
      address: "0xD1EDF70F0a7fB040B5E7Af9195765c69227Be8b9",
      network: "ERC20",
    },
    {
      name: "USDT",
      icon: "/assets/usdt.png",
      address: "TUo6zctLwSajuA2zhVU2vP7VAAvMV5CjKe",
      network: "TRC20",
    },
    {
      name: "Solana",
      icon: "/assets/solana.png",
      address: "8D5s7RXiH2jMUaTBiXPTjzMvV8rKbQ7uongb8Z7QmYrU",
    },
    {
      name: "Bitcoin",
      icon: "/assets/bitcoin.png",
      address: "bc1qjfuqwwjmraue3uxrwvrr6q5mlkxmsh7078rt79",
    },
    {
      name: "Polygon",
      icon: "/assets/polygon.png",
      address: "0xCf58506a859c502E126c7728A550c1868325e842",
    },
  ];

  const copyToClipboard = (address) => {
    const shortenAddress = (addr) => `${addr.slice(0, 4)}...${addr.slice(-4)}`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(address).then(
        () => {
          toast.success(`Copied! ${shortenAddress(address)}`);
        },
        () => {
          toast.error("Failed to copy address. Please try again.");
        }
      );
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = address;
      textArea.style.position = "absolute";
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.success(`Copied ${shortenAddress(address)}!`);
      } catch (err) {
        toast.error("Failed to copy address. Please try again.");
      }
      document.body.removeChild(textArea);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const openRefundModal = () => {
    setIsRefundModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeRefundModal = () => {
    setIsRefundModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeModal();
        closePrivacyModal();
        closeRefundModal();
      }
    };

    if (isModalOpen || isPrivacyModalOpen || isRefundModalOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isModalOpen, isPrivacyModalOpen, isRefundModalOpen]);

  return (
    <footer className="footer" id="about">
      <section id="contact" className="footer-container">
        <div className="social-icons2">
          <motion.a
            href="https://pump.fun/coin/"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            className="bmc-link-foot"
          >
            <img
              src="/assets/pumpfun.png"
              alt="Pumpfun"
              className="bmc-icon-foot"
            />
          </motion.a>
          <motion.a
            href="https://dexscreener.com/solana/"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            className="bmc-link-foot"
          >
            <img src="/assets/dex.png" alt="Kraken" className="bmc-icon-foot" />
          </motion.a>
          <motion.a
            href="https://buymeacoffee.com/jittercore"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            className="bmc-link-foot"
          >
            <img
              src="/assets/bmc-logo.png"
              alt="Buy Me A Coffee"
              className="bmc-icon-foot"
            />
          </motion.a>
          <motion.a
            href="https://www.instagram.com/jittercore?igsh=Mm44MXdmdnYyb2Np"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaSquareInstagram />
          </motion.a>
          <motion.a
            href="https://x.com/jittercoresol"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaSquareXTwitter />
          </motion.a>
          <motion.a
            href="https://www.snapchat.com/add/jittercore"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaSnapchatSquare />
          </motion.a>
          <motion.a
            href="https://www.youtube.com/@Jittercore"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaYoutubeSquare />
          </motion.a>
          <motion.a
            href="https://www.t.me/jittercore"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaTelegramPlane />
          </motion.a>
        </div>
        <div className="footer-logo-contact">
          <a href="mailto:coop@jittercore.art" className="contact-email">
            coop@jittercore.art
          </a>
        </div>
      </section>

      <div className="footer-main">
        <div className="crypto-section" id="donations">
          <h2 className="crypto-title">Support me</h2>
          <div className="crypto-addresses">
            {cryptoAddresses.map((crypto) => (
              <div className="crypto-item" key={crypto.name}>
                <img
                  src={crypto.icon}
                  alt={`${crypto.name} Icon`}
                  className="crypto-icon"
                />
                <span className="crypto-name">
                  {crypto.name}
                  {crypto.network && (
                    <span className="network-badge">{crypto.network}</span>
                  )}
                </span>
                <span className="crypto-address">{crypto.address}</span>
                <button
                  className="copy-button"
                  onClick={() => copyToClipboard(crypto.address)}
                >
                  <FaCopy />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="media-kit-section">
          <h2 className="media-kit-title">Media Kit for Ad</h2>
          <img
            src="/assets/pngMKit.png"
            alt="Media Kit"
            className="media-kit-image"
            onClick={openModal}
          />
        </div>
        <div className="footer-logo-contact2">
          <a href="mailto:coop@jittercore.art" className="contact-email">
            coop@jittercore.art
          </a>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <button
          style={{ marginRight: "20px" }}
          onClick={openPrivacyModal}
          className="policy-button"
        >
          Privacy Policy
        </button>
        <button onClick={openRefundModal} className="policy-button">
          Refund Policy
        </button>
      </div>

      <motion.div
        className="footer-copyright2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p>© {new Date().getFullYear()} Jittercore. All rights reserved.</p>
      </motion.div>

      <div
        className={`modal-overlay ${
          isModalOpen ? "modal-open" : "modal-closed"
        }`}
        onClick={closeModal}
      >
        {isModalOpen && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src="/assets/pngMKit.png"
              alt="Media Kit"
              className="modal-image"
            />
            <a
              href="/assets/pdfMKit.pdf"
              download
              className="download-pdf-button"
            >
              <FaDownload className="downIcon" /> Download
            </a>
            <button className="close-modal-button" onClick={closeModal}>
              &times;
            </button>
          </div>
        )}
      </div>
      <AnimatePresence>
        {isPrivacyModalOpen && (
          <motion.div
            className="unique-consent-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closePrivacyModal}
          >
            <motion.div
              className="unique-consent-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-arrow-down"></div>
              <h3 className="consent-modal-title">Privacy Policy</h3>
              <div className="consent-modal-body">
                <p className="consent-modal-text2">
                  We value your privacy and strive to protect your personal
                  information. This Privacy Policy describes how we collect,
                  use, and share your data when you engage with our services,
                  purchase our AI video courses, or request custom comedic
                  content.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">
                    Collection of Information
                  </strong>
                  <br />
                  We may collect your email address, payment details (handled
                  securely by third-party payment processors), and any content
                  you provide for AI-driven video transformation.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">Use of Data</strong>
                  <br />
                  We utilize your personal information to process your orders,
                  deliver video content, improve our AI models, and send you
                  relevant updates or promotions (if you opt in).
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">Data Sharing</strong>
                  <br />
                  We do not sell or rent your personal information to third
                  parties. However, we may share your data with trusted
                  third-party services that help us operate our business
                  (payment processors, web hosting, analytics tools).
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">Cookies</strong>
                  <br />
                  We may use cookies and similar technologies to enhance your
                  experience on our site, track usage, and provide personalized
                  content.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">Security</strong>
                  <br />
                  We implement reasonable measures to protect your information
                  against unauthorized access. However, no online transmission
                  is entirely secure.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">AI Content Use</strong>
                  <br />
                  By uploading or providing content, you acknowledge that it may
                  be processed by AI models to generate comedic or custom video
                  outputs. We take reasonable steps to secure any data used in
                  AI processing.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">Contact Us</strong>
                  <br />
                  If you have any questions regarding our Privacy Policy, please
                  contact us at coop@jittercore.art.
                </p>
                <button
                  className="consent-confirm-button enabled"
                  onClick={closePrivacyModal}
                >
                  OK
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isRefundModalOpen && (
          <motion.div
            className="unique-consent-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeRefundModal}
          >
            <motion.div
              className="unique-consent-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-arrow-down"></div>
              <h3 className="consent-modal-title">Refund Policy</h3>
              <div className="consent-modal-body">
                <p className="consent-modal-text2">
                  We appreciate your support and strive to provide high-quality,
                  AI-generated comedic content and courses. This Refund Policy
                  outlines the conditions under which refunds may be granted.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">
                    Courses and Digital Goods
                  </strong>
                  <br />
                  Due to the nature of digital products, all sales of
                  AI-generated videos, courses, or related materials are final.
                  We do not offer refunds once the order is processed and
                  delivered.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">Custom Orders</strong>
                  <br />
                  For personalized video requests, we dedicate time and AI
                  resources to fulfill your specific requirements. Refunds for
                  custom orders are only issued if there is a clear failure to
                  deliver the agreed-upon service (e.g., video is not provided
                  at all). Minor creative differences or subjective preferences
                  are not grounds for a refund.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">
                    Payment in Cryptocurrency
                  </strong>
                  <br />
                  Payments made via cryptocurrency are subject to market
                  volatility. If a refund is deemed appropriate according to
                  section (2), the refund will be processed in cryptocurrency at
                  the USD equivalent rate at the time of refund issuance, minus
                  any applicable transaction or gas fees.
                </p>
                <p className="consent-modal-text2">
                  <strong className="titleAgree">Contact Us</strong>
                  <br />
                  If you believe you qualify for a refund or have any questions,
                  please contact us at coop@jittercore.art with your order
                  details, and we will review your request on a case-by-case
                  basis.
                </p>
                <button
                  className="consent-confirm-button enabled"
                  onClick={closeRefundModal}
                >
                  OK
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </footer>
  );
};

export default Footer;
