import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faCheck,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./Token.css";
import { TbWorldShare } from "react-icons/tb";
import { toast } from "sonner";

const Testimonials = () => {
  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [marketCap, setMarketCap] = useState("—");
  const [holders, setHolders] = useState("—");

  const contractAddress = "0000000000000000000000000000000000000000000";
  const network = "solana";
  const copyToClipboard = (address) => {
    const shortenAddress = (addr) => `${addr.slice(0, 4)}...${addr.slice(-4)}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(address).then(
        () => {
          toast.success(`CA copied! ${shortenAddress(address)}`);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000);
        },
        () => {
          toast.error("Failed to copy address. Please try again.");
        }
      );
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = address;
      textArea.style.position = "absolute";
      textArea.style.left = "-9999px";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.success(`Copied ${shortenAddress(address)}!`);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch {
        toast.error("Failed to copy address. Please try again.");
      }
      document.body.removeChild(textArea);
    }
  };

  const openTitleModal = () => {
    setIsTitleModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeTitleModal = () => {
    setIsTitleModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") closeTitleModal();
    };
    if (isTitleModalOpen) {
      window.addEventListener("keydown", handleEsc);
    }
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isTitleModalOpen]);

  const fetchTokenData = async () => {
    try {
      const poolsUrl = `https://api.geckoterminal.com/api/v2/networks/${network}/tokens/${contractAddress}/pools`;
      const poolsResponse = await fetch(poolsUrl, {
        headers: { Accept: "application/json;version=20230302" },
      });

      if (!poolsResponse.ok)
        throw new Error(`Pools request failed: ${poolsResponse.status}`);
      const poolsData = await poolsResponse.json();

      const firstPoolId = poolsData?.data?.[0]?.id;
      if (!firstPoolId) throw new Error("None");

      const poolAddress = firstPoolId.split("_")[1];

      const poolInfoUrl = `https://api.geckoterminal.com/api/v2/networks/${network}/pools/${poolAddress}`;
      const poolInfoResponse = await fetch(poolInfoUrl, {
        headers: { Accept: "application/json;version=20230302" },
      });

      if (!poolInfoResponse.ok)
        throw new Error(`Pool info request failed: ${poolInfoResponse.status}`);
      const poolInfo = await poolInfoResponse.json();

      const attributes = poolInfo?.data?.attributes;
      const marketCapValue = parseFloat(attributes?.fdv_usd) || "—";
      setMarketCap(
        marketCapValue !== "—" ? `$${(marketCapValue / 1000).toFixed(2)}K` : "—"
      );

      const heliusApiKey = "7278c676-c36c-4d0d-81cd-86d490904a2e";
      const heliusUrl = `https://mainnet.helius-rpc.com/?api-key=${heliusApiKey}`;

      let holdersSet = new Set();
      let cursor = null;
      const limit = 1000;

      while (true) {
        const requestBody = {
          jsonrpc: "2.0",
          id: "fetch_holders",
          method: "getTokenAccounts",
          params: {
            mint: contractAddress,
            limit,
            ...(cursor && { cursor }),
            options: { showZeroBalance: false },
          },
        };

        const response = await fetch(heliusUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok)
          throw new Error(`Holders request failed: ${response.status}`);
        const data = await response.json();

        if (!data.result || data.result.token_accounts.length === 0) {
          break;
        }

        data.result.token_accounts.forEach((account) => {
          holdersSet.add(account.owner);
        });

        cursor = data.result.cursor;

        if (!cursor) break;
      }

      const totalHolders = holdersSet.size;
      setHolders(totalHolders);
    } catch (error) {
      console.error("Error:", error);
      setHolders("—");
    }
  };

  useEffect(() => {
    fetchTokenData();
    const intervalId = setInterval(fetchTokenData, 600000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section id="token" className="token-section">
      <h2 className="token-title">
        Official Meme Token
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="title-info-icon"
          onClick={openTitleModal}
        />
      </h2>
      <div className="token-content-wrapper">
        <div className="left-content">
          <div className="gif-and-address">
            {/* <img
              src="/assets/IMG_1289.jpg"
              alt="Token logo"
              className="token-gif"
            /> */}
            <div className="address-field">
              <span className="address-text">CA: {contractAddress}</span>
              <div className="address-icons">
                <a
                  href="https://dexscreener.com/solana/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="external-link-icon"
                >
                  <TbWorldShare />
                </a>
                <div
                  className="copy-icon"
                  onClick={() => copyToClipboard(contractAddress)}
                >
                  <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
                </div>
              </div>
            </div>
          </div>
          <p className="note-text2">
            Note: The official token will operate on the Solana network. Please
            follow our official channels for any updates regarding its launch,
            utilities, or other relevant announcements.
          </p>
        </div>
        <div className="right-content">
          <div className="market-info">
            <div className="market-cap-block">
              <p className="big-value">
                {typeof marketCap === "number"
                  ? marketCap.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })
                  : marketCap}
              </p>
              <p className="small-label">Market Cap</p>
            </div>
            <div className="holders-block">
              <p className="big-value">
                {typeof holders === "number"
                  ? holders.toLocaleString()
                  : holders}
              </p>
              <p className="small-label">Holders</p>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isTitleModalOpen && (
          <motion.div
            className="unique-title-modal-overlay2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeTitleModal}
          >
            <motion.div
              className="unique-title-modal-content2"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="title-modal-heading">Official Meme Token</h3>
              <p className="title-modal-text">
                Many fake tokens collapse after launch. We are introducing an
                <b> official, stable meme token</b> on Solana to address this
                problem. Our rapidly growing community fuels consistent demand
                and sustainable price growth. Join early before the price takes
                off!
              </p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Testimonials;
