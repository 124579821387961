// App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import InfinityLoader from "./components/InfinityLoader";
import Separator from "./components/Separator";
import Courses from "./components/Courses";
import Token from "./components/Token";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import VideoCarousel from "./components/VideoCarousel";
import GratitudeModal from "./components/GratitudeModal";
import DetailsModal from "./components/DetailsModal";
import { initGA, logPageView } from "./analytics";
import { Toaster } from "sonner";
import SlickButton from "./components/SlickButton";

import "./App.css";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isGratitudeOpen, setIsGratitudeOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    initGA();

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <InfinityLoader />;
  }

  const openGratitudeModal = () => {
    setIsGratitudeOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeGratitudeModal = () => {
    setIsGratitudeOpen(false);
    document.body.style.overflow = "auto";
  };

  const closeDetailsModal = () => {
    setIsDetailsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <Router>
      <Analytics />
      <div className="App">
        <Header onOpenGratitude={openGratitudeModal} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Main />
                <Separator />
                <VideoCarousel />
                <Separator />
                <Testimonials />
                <Separator />
                <Courses />
                <Separator />
                <Token />
                <Separator />
              </>
            }
          />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
        <Footer />
        <SlickButton />
        <Toaster
          toastOptions={{
            className: "sonner-toast",
          }}
        />
        <GratitudeModal
          isOpen={isGratitudeOpen}
          onClose={closeGratitudeModal}
        />
        <DetailsModal isOpen={isDetailsOpen} onClose={closeDetailsModal} />
      </div>
    </Router>
  );
};

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default App;
