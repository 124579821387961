import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./GratitudeModal.css"; // стили модалки
// import { supportersList } from "./supportersList";

const GratitudeModal = ({ isOpen, onClose }) => {
  // Обработчик кликов внутри модального окна
  const handleClickInside = (e) => {
    // Если это был клик пользователя, закрыть модалку
    if (e.type === "click") {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="gratitude-global-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <motion.div
            className="gratitude-global-content"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={handleClickInside}
            onScroll={(e) => e.stopPropagation()}
          >
            <h3 className="gratitude-global-title">Thank You!</h3>
            <p className="gratitude-global-subtitle">
              We appreciate all your support
            </p>

            <ul className="gratitude-global-list">
              {/* {supportersList.map((supporter, idx) => (
                <li key={idx} className="gratitude-global-item">
                  <span className="gratitude-global-nick">
                    {supporter.nick}
                  </span>
                  <span className="gratitude-global-amount">
                    {supporter.amount}
                  </span>
                </li>
              ))} */}
            </ul>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GratitudeModal;
