/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { FaQuestionCircle, FaCheck } from "react-icons/fa";
import { toast } from "sonner";
import { motion, AnimatePresence } from "framer-motion";
import { LuEye, LuEyeClosed } from "react-icons/lu";
import "./VideoCarousel.css";

const generateToken = () => {
  return btoa(`order-${Date.now()}-${Math.random()}`);
};

const VideoCarousel = () => {
  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const openTitleModal = () => {
    setIsTitleModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeTitleModal = () => {
    setIsTitleModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const videos = [
    {
      src: "/assets/ex1.mp4",
      alt: "Example video 1",
      promptPrice: 21,
      bmcUrl: "https://buymeacoffee.com/jittercore/e/373189",
      coinPayments: {
        merchantId: "3674a70f6f7ed4e366f374672f989cb1",
        currency: "USD",
        amount: 21,
        itemName: "Viral Prompt #3",
        itemDesc: "Viral prompt created by @jittercore.",
        successUrl: "https://limewire.com/?referrer=pq7i8xx7p2",
        cancelUrl: "https://jittercore.art/cancel",
      },
    },
    {
      src: "/assets/ex00.mp4",
      alt: "Example video 2",
      promptPrice: 29,
      bmcUrl: "https://buymeacoffee.com/jittercore/e/373186",
      coinPayments: {
        merchantId: "3674a70f6f7ed4e366f374672f989cb1",
        currency: "USD",
        amount: 29,
        itemName: "Viral Prompt #1",
        itemDesc: "The most viral prompt @jittercore has ever made.",
        successUrl: "https://limewire.com/?referrer=q2a8xx7p2",
        cancelUrl: "https://jittercore.art/cancel",
      },
    },
    {
      src: "/assets/ex3.mp4",
      alt: "Example video 3",
      promptPrice: 15,
      bmcUrl: "https://buymeacoffee.com/jittercore/e/373188",
      coinPayments: {
        merchantId: "3674a70f6f7ed4e366f374672f989cb1",
        currency: "USD",
        amount: 15,
        itemName: "Viral Prompt #2",
        itemDesc: "Viral prompt created by @jittercore.",
        successUrl: "https://limewire.com/?referrer=r3v3xx7p2",
        cancelUrl: "https://jittercore.art/cancel",
      },
    },
    {
      src: "/assets/ex4.mp4",
      alt: "Example video 4",
      promptPrice: 18,
      bmcUrl: "https://buymeacoffee.com/jittercore/e/373191",
      coinPayments: {
        merchantId: "3674a70f6f7ed4e366f374672f989cb1",
        currency: "USD",
        amount: 18,
        itemName: "Viral Prompt #5",
        itemDesc: "Viral prompt created by @jittercore.",
        successUrl: "https://limewire.com/?referrer=y9u2xx7p2",
        cancelUrl: "https://jittercore.art/cancel",
      },
    },
    {
      src: "/assets/ex000.mp4",
      alt: "Example video 5",
      promptPrice: 25,
      bmcUrl: "https://buymeacoffee.com/jittercore/e/373190",
      coinPayments: {
        merchantId: "3674a70f6f7ed4e366f374672f989cb1",
        currency: "USD",
        amount: 25,
        itemName: "Viral Prompt #4",
        itemDesc: "Viral prompt created by @jittercore.",
        successUrl: "https://limewire.com/?referrer=q7q3xx7p2",
        cancelUrl: "https://jittercore.art/cancel",
      },
    },

    {
      src: null,
      alt: "All prompts + Premium course",
      promptPrice: 99,
      bmcUrl: "https://buymeacoffee.com/jittercore/e/373769",
      coinPayments: {
        merchantId: "3674a70f6f7ed4e366f374672f989cb1",
        currency: "USD",
        amount: 99,
        itemName: "All prompts + Premium course",
        itemDesc: "All prompts + Premium course from @jittercore.",
        successUrl: "https://limewire.com/?referrer=packxx7p2",
        cancelUrl: "https://jittercore.art/cancel",
      },
    },
  ];

  const recommendedPackIndex = videos.findIndex(
    (v) => v.alt === "All prompts + Premium course"
  );
  const recommendedPack =
    recommendedPackIndex !== -1 ? videos[recommendedPackIndex] : null;

  const price = 99.0;
  const merchantId = "3674a70f6f7ed4e366f374672f989cb1";
  const currency = "USD";
  const [token, setToken] = useState(generateToken());
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [openPromptIndex, setOpenPromptIndex] = useState(null);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [isConsentConfirmed, setIsConsentConfirmed] = useState(false);
  const [isConsentPermission, setIsConsentPermission] = useState(false);
  const [isOrderActive, setIsOrderActive] = useState(false);
  const [isDurationModalOpen, setIsDurationModalOpen] = useState(false);

  const openConsentModal = () => {
    setIsConsentModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeConsentModal = () => {
    setIsConsentModalOpen(false);
    document.body.style.overflow = "auto";
  };
  const handleConsentConfirm = () => {
    if (isConsentConfirmed && isConsentPermission) {
      setIsOrderActive(true);
      toast.success("You have agreed to the terms!");
      closeConsentModal();
    } else {
      toast.error("Please agree to all the terms to proceed.");
    }
  };
  const openDurationModal = () => {
    setIsDurationModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeDurationModal = () => {
    setIsDurationModalOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeConsentModal();
        closeDurationModal();
        closeTitleModal();
      }
    };
    if (isConsentModalOpen || isDurationModalOpen || isTitleModalOpen) {
      window.addEventListener("keydown", handleEsc);
    }
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isConsentModalOpen, isDurationModalOpen, isTitleModalOpen]);

  const handleBadgeClick = (e, index) => {
    e.stopPropagation();
    setOpenPromptIndex((prev) => (prev === index ? null : index));
  };
  const handleOverlayClick = () => {
    setOpenPromptIndex(null);
  };
  const stopPropagation = (e) => e.stopPropagation();

  const handleCryptoSubmit = (e, index) => {
    e.stopPropagation();
    const storedTokens = JSON.parse(localStorage.getItem("validTokens")) || [];
    storedTokens.push(token);
    localStorage.setItem("validTokens", JSON.stringify(storedTokens));
  };

  return (
    <section id="videocarousel" className="video-carousel">
      <h2 className="carousel-title">
        Edits and Prompts
        <FaQuestionCircle
          className="title-info-icon"
          onClick={openTitleModal}
        />
      </h2>
      <div className="video-container">
        {videos
          .filter((v) => v.src)
          .map((video, index) => {
            const isHovered = hoveredIndex === index;
            const isOpen = openPromptIndex === index;
            const cp = video.coinPayments;
            return (
              <div className="video-wrapper" key={index}>
                <div
                  className="badge-container"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={(e) => handleBadgeClick(e, index)}
                >
                  <div className="gradient-badge">
                    <span className="badge-text">See full prompt</span>
                    {isHovered || isOpen ? (
                      <LuEye className="badge-icon" />
                    ) : (
                      <LuEyeClosed className="badge-icon" />
                    )}
                  </div>
                </div>
                <div className="video-overlay-container">
                  <video
                    className="carousel-video"
                    src={video.src}
                    autoPlay
                    muted
                    loop
                    playsInline
                    alt={video.alt}
                    onClick={stopPropagation}
                  />
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        className="prompt-overlay"
                        onClick={handleOverlayClick}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div
                          className="prompt-overlay-content"
                          onClick={stopPropagation}
                        >
                          <p className="prompt-price">
                            <span className="dollar-sign">$</span>
                            {video.promptPrice}
                          </p>
                          <div className="prompt-buttons">
                            <form
                              action="https://www.coinpayments.net/index.php"
                              method="post"
                              target="_top"
                              onSubmit={(e) => handleCryptoSubmit(e, index)}
                              className="crypto-form"
                            >
                              <input
                                type="hidden"
                                name="cmd"
                                value="_pay_simple"
                              />
                              <input type="hidden" name="reset" value="1" />
                              <input
                                type="hidden"
                                name="merchant"
                                value={cp.merchantId}
                              />
                              <input
                                type="hidden"
                                name="currency"
                                value={cp.currency}
                              />
                              <input
                                type="hidden"
                                name="amountf"
                                value={cp.amount}
                              />
                              <input
                                type="hidden"
                                name="item_name"
                                value={cp.itemName}
                              />
                              <input
                                type="hidden"
                                name="item_desc"
                                value={cp.itemDesc}
                              />
                              <input
                                type="hidden"
                                name="success_url"
                                value={cp.successUrl}
                              />
                              <input
                                type="hidden"
                                name="cancel_url"
                                value={cp.cancelUrl}
                              />
                              <input
                                type="hidden"
                                name="custom"
                                value={token}
                              />
                              <button
                                type="submit"
                                className="bg-white text-gray-900 font-semibold py-1.5 px-4 hover:bg-gray-300 transition-colors duration-300 flex items-center justify-center font-poppins-medium"
                              >
                                Crypto
                              </button>
                            </form>
                            <a
                              href={video.bmcUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bmc-button-link3"
                            >
                              <img
                                src="/assets/bmc.png"
                                alt="Buy Me a Coffee"
                                className="bmc-button-img3"
                              />
                            </a>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            );
          })}
      </div>
      <div className="price-section-transform">
        <p className="price-display-transform">
          <span className="dollar-sign">$</span>
          {price}
        </p>
        <div className="duration-display-container">
          <p className="duration-display">20 seconds</p>
          <FaQuestionCircle
            className="duration-info-icon text-gray-400 cursor-pointer"
            onClick={openDurationModal}
          />
        </div>
      </div>
      <div className="order-buttons-container">
        <form
          action="https://www.coinpayments.net/index.php"
          method="post"
          target="_top"
          onSubmit={(e) => {
            if (!isOrderActive) {
              e.preventDefault();
              openConsentModal();
            }
          }}
        >
          <input type="hidden" name="cmd" value="_pay_simple" />
          <input type="hidden" name="reset" value="1" />
          <input type="hidden" name="merchant" value={merchantId} />
          <input type="hidden" name="currency" value={currency} />
          <input type="hidden" name="amountf" value={price} />
          <input
            type="hidden"
            name="item_desc"
            value="Fully customized video from Michael based on any description you provide / Collaboration."
          />
          <input
            type="hidden"
            name="item_name"
            value="AI Video Transformation"
          />
          <input
            type="hidden"
            name="success_url"
            value={`https://t.me/reduxc?token=${token}`}
          />
          <input
            type="hidden"
            name="cancel_url"
            value="https://jittercore.art/cancel"
          />
          <input type="hidden" name="custom" value={token} />
          <button
            type="submit"
            className={`order-button bg-white text-gray-900 font-semibold py-2.5 px-4 rounded-lg hover:bg-gray-300 font-poppins-medium ${
              isOrderActive ? "active-order-button" : ""
            }`}
          >
            Order Now
          </button>
        </form>
        <a
          href="https://buymeacoffee.com/jittercore/e/350732"
          target="_blank"
          rel="noopener noreferrer"
          className="bmc-button-link2"
        >
          <img
            src="/assets/bmc.png"
            alt="Buy Me a Coffee"
            className="bmc-button-img2"
          />
        </a>
      </div>
      <p className="note-text">
        Note: After payment, you will be redirected to a Telegram chat to
        provide your requirements and upload your video for transformation.
      </p>
      <AnimatePresence>
        {isConsentModalOpen && (
          <motion.div
            className="unique-consent-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeConsentModal}
          >
            <motion.div
              className="unique-consent-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-arrow-down"></div>
              <h3 className="consent-modal-title">Terms and Consent</h3>
              <div className="consent-modal-body">
                <p className="consent-modal-text">
                  <strong className="titleAgree">Copyright Confirmation</strong>
                </p>
                <p className="consent-modal-text">
                  I confirm that the content I am providing is my own or I have
                  all rights to use this content and permit its modification.
                </p>
                <p className="consent-modal-text">
                  <strong className="titleAgree">Permission for Use</strong>
                </p>
                <p className="consent-modal-text">
                  I grant permission for the use and modification of the
                  provided content for publication on the Instagram account as
                  part of the AI Video Transformation service.
                </p>
                <p className="consent-modal-text">
                  <strong className="titleAgree">
                    Responsibility for Third-Party Content
                  </strong>
                </p>
                <p className="consent-modal-text">
                  I confirm that the content I have provided does not infringe
                  upon third-party copyrights and take full responsibility for
                  any claims related to the use of this content.
                </p>
                <p className="consent-modal-text">
                  <strong className="titleAgree">Terms of Use</strong>
                </p>
                <ul className="consent-modal-list">
                  <li>
                    The content will be used solely for the purposes of the AI
                    Video Transformation service.
                  </li>
                  <li>
                    I understand that the provided content may be altered using
                    AI and agree to such modifications.
                  </li>
                  <li>
                    I understand that no additional compensation will be
                    provided for the use of the content.
                  </li>
                  <li>
                    I understand that the artist may refuse to create a video
                    for ethical or moral reasons.
                  </li>
                </ul>
                <div className="consent-checkbox-group">
                  <div className="consent-checkbox-item">
                    <input
                      type="checkbox"
                      id="consent-confirm"
                      checked={isConsentConfirmed}
                      onChange={(e) => setIsConsentConfirmed(e.target.checked)}
                    />
                    <label
                      htmlFor="consent-confirm"
                      className="consent-checkbox-text"
                    >
                      I confirm that I have provided accurate information and
                      agree to the terms.
                    </label>
                  </div>
                  <div className="consent-checkbox-item">
                    <input
                      type="checkbox"
                      id="consent-permission"
                      checked={isConsentPermission}
                      onChange={(e) => setIsConsentPermission(e.target.checked)}
                    />
                    <label
                      htmlFor="consent-permission"
                      className="consent-checkbox-text"
                    >
                      I grant permission for the use and modification of the
                      provided content in accordance with the above conditions.
                    </label>
                  </div>
                </div>
                <button
                  className={`consent-confirm-button ${
                    isConsentConfirmed && isConsentPermission
                      ? "enabled"
                      : "disabled"
                  }`}
                  onClick={handleConsentConfirm}
                  disabled={!(isConsentConfirmed && isConsentPermission)}
                >
                  <FaCheck className="check-icon" /> Confirm
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isDurationModalOpen && (
          <motion.div
            className="unique-duration-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeDurationModal}
          >
            <motion.div
              className="unique-duration-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <h3 className="duration-modal-title">Video Transformation</h3>
              <p className="duration-modal-description">
                After payment, Michael will transform your video—whether it's of
                your pet, yourself, or your friends—into a unique
                Jittercore-style edit. The final video, 20 seconds video will be
                posted as a Storie on @jittercore profile with a mention of you.
                This is a great way to see your favorite moments reimagined with
                a dynamic, glitchy aesthetic. Secure your spot now and watch
                your video take on a whole new life!
              </p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isTitleModalOpen && (
          <motion.div
            className="unique-title-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeTitleModal}
          >
            <motion.div
              className="unique-title-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <h3 className="title-modal-heading">Edits and Prompts Info</h3>
              <p className="title-modal-text">
                By purchasing any single prompt, you also receive a mini-course
                on how to best utilize the prompts you buy, ensuring the most
                effective results.
              </p>
              <h4 className="recommended-pack-title">Recommended pack</h4>
              <div className="recommended-pack-badge">
                <span className="recommended-pack-badge-text">
                  All prompts + Premium course
                </span>
              </div>
              <p className="price-display-transform2">
                <span className="dollar-sign">$</span>
                99
              </p>
              <div
                className="recommended-pack-buttons"
                style={{ marginTop: "15px" }}
              >
                {recommendedPack && (
                  <>
                    <form
                      action="https://www.coinpayments.net/index.php"
                      method="post"
                      target="_top"
                      onSubmit={(e) =>
                        handleCryptoSubmit(e, recommendedPackIndex)
                      }
                      className="crypto-form"
                    >
                      <input type="hidden" name="cmd" value="_pay_simple" />
                      <input type="hidden" name="reset" value="1" />
                      <input
                        type="hidden"
                        name="merchant"
                        value={recommendedPack.coinPayments.merchantId}
                      />
                      <input
                        type="hidden"
                        name="currency"
                        value={recommendedPack.coinPayments.currency}
                      />
                      <input
                        type="hidden"
                        name="amountf"
                        value={recommendedPack.coinPayments.amount}
                      />
                      <input
                        type="hidden"
                        name="item_name"
                        value={recommendedPack.coinPayments.itemName}
                      />
                      <input
                        type="hidden"
                        name="item_desc"
                        value={recommendedPack.coinPayments.itemDesc}
                      />
                      <input
                        type="hidden"
                        name="success_url"
                        value={recommendedPack.coinPayments.successUrl}
                      />
                      <input
                        type="hidden"
                        name="cancel_url"
                        value={recommendedPack.coinPayments.cancelUrl}
                      />
                      <input type="hidden" name="custom" value={token} />
                      <button
                        type="submit"
                        className="bg-white text-gray-900 font-semibold py-1.5 px-4 rounded-lg hover:bg-gray-300 transition-colors duration-300 flex items-center justify-center font-poppins-medium"
                      >
                        Crypto
                      </button>
                    </form>
                    <a
                      href={recommendedPack.bmcUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bmc-button-link3"
                    >
                      <img
                        src="/assets/bmc.png"
                        alt="Buy Me a Coffee"
                        className="bmc-button-img3"
                      />
                    </a>
                  </>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default VideoCarousel;
