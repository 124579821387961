import React, { useState } from "react";
import styles from "./Main.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const generateToken = () => {
  return btoa(`order-${Date.now()}-${Math.random()}`);
};

const Main = () => {
  const [token] = useState(generateToken());
  const price = 35.0;
  const merchantId = "3674a70f6f7ed4e366f374672f989cb1";
  const currency = "USD";

  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.getElementById(targetId);
    const header = document.querySelector(".header");

    if (header && target) {
      const headerHeight = header.offsetHeight;
      const targetPosition =
        target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    } else {
      console.warn("Header or target element not found");
    }
  };

  const tshirtImages = [
    "assets/merch.png",
    "assets/merch2.png",
    "assets/merch3.png",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const handleTshirtClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % tshirtImages.length);
  };

  const [selectedSize, setSelectedSize] = useState(null);

  const sizes = [
    { label: "S", disabled: true },
    { label: "M", disabled: true },
    { label: "L", disabled: true },
    { label: "XL", disabled: true },
  ];

  const isSizeSelected = !!selectedSize;

  return (
    <main className={styles.main} id="about">
      <motion.div
        className={styles.mainContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className={styles.leftSection}>
          <motion.h1
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 50 }}
          >
            Master the Inner Workings
          </motion.h1>
          <motion.p
            className={styles.descriptionText2}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Dominate the spotlight and build unstoppable brand recognition ever.
          </motion.p>

          <div className={styles.reelsContainer}>
            <div className={styles.reelWrapper}>
              <video
                className={styles.reel}
                src="assets/video3.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className={styles.viewsOverlay}>
                <FontAwesomeIcon icon={faEye} className={styles.eyeIcon} />
                47.2M
              </div>
            </div>

            <div className={styles.reelWrapper}>
              <video
                className={styles.reel}
                src="assets/bruni2.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className={styles.viewsOverlay}>
                <FontAwesomeIcon icon={faEye} className={styles.eyeIcon} />
                14.6M
              </div>
            </div>

            <div className={styles.reelWrapper}>
              <video
                className={styles.reel}
                src="assets/charm2.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className={styles.viewsOverlay}>
                <FontAwesomeIcon icon={faEye} className={styles.eyeIcon} />
                22.8M
              </div>
            </div>
          </div>

          <a
            href="#academy"
            onClick={(e) => handleScroll(e, "academy")}
            className={styles.ctaButton}
          >
            Explore the Academy →
          </a>
        </div>
      </motion.div>

      <div className="separator2"></div>
      <motion.div
        className={styles.tokenData}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <h2 className={styles.merchTitle} id="merch">
          Exclusive Series
        </h2>
        <div className={styles.merchSection}>
          <div className={styles.merchLeft}>
            <div className={styles.tshirtContainer} onClick={handleTshirtClick}>
              <AnimatePresence mode="wait">
                <motion.img
                  key={currentIndex}
                  src={tshirtImages[currentIndex]}
                  alt="T-shirt"
                  className={styles.tshirtImage}
                  initial={{ opacity: 0, scale: 1 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                />
              </AnimatePresence>
            </div>
          </div>
          <div className={styles.merchRight}>
            <h3
              style={{ color: "white" }}
              className="courses-plan-name text-xl font-medium font-poppins-medium"
            >
              `Jittercore` Tee
            </h3>
            <p className={styles.descMerch} style={{ color: "grey" }}>
              This limited collection of 99 pieces ensures exclusivity and
              uniqueness. Each T-shirt is individually numbered, guaranteeing
              your place in the series.
            </p>
            <p className={styles.priceDisplayTransform}>
            <span className="dollar-sign">$</span>
              {price}</p>
            <div className={styles.soldText}>Sold Out</div>
            <div className={styles.sizeBadgeContainer}>
              {sizes.map((size) => {
                const isDisabled = size.disabled;
                const isSelected = selectedSize === size.label;
                return (
                  <button
                    key={size.label}
                    type="button"
                    onClick={() => {
                      if (!isDisabled) {
                        setSelectedSize(size.label);
                      }
                    }}
                    className={`
                      ${styles.sizeBadge} 
                      ${isDisabled ? styles.disabledSize : ""}
                      ${isSelected ? styles.selectedSize : ""}
                    `}
                  >
                    {size.label}
                  </button>
                );
              })}
            </div>

            <div className={styles.buttonRow}>
              <form
                action="https://www.coinpayments.net/index.php"
                method="post"
                target="_top"
              >
                <input type="hidden" name="cmd" value="_pay_simple" />
                <input type="hidden" name="reset" value="1" />
                <input type="hidden" name="want_shipping" value="1" />
                <input type="hidden" name="merchant" value={merchantId} />
                <input type="hidden" name="currency" value={currency} />
                <input type="hidden" name="amountf" value={price} />
                <input
                  type="hidden"
                  name="item_name"
                  value="Limited `Jittercore` Tee"
                />
                <input
                  type="hidden"
                  name="item_desc"
                  value="This limited collection of 99 pieces ensures exclusivity and uniqueness. Each T-shirt is individually numbered."
                />
                <input
                  type="hidden"
                  name="success_url"
                  value="https://limewire.com/?referrer=pq7i8xx7p2"
                />
                <input
                  type="hidden"
                  name="cancel_url"
                  value="https://jittercore.art/cancel"
                />
                <input type="hidden" name="custom" value={token} />

                <button
                  type="submit"
                  className={`
                    bg-white text-gray-900 font-semibold py-2.5 px-4 rounded-lg 
                    hover:bg-gray-300 transition-colors duration-300 flex items-center 
                    justify-center font-poppins-medium 
                    ${
                      !isSizeSelected
                        ? styles.disabledButton
                        : styles.enabledButton
                    }
                  `}
                  disabled={!isSizeSelected}
                >
                  Buy with crypto
                </button>
              </form>
              <a
                href="https://buymeacoffee.com/jittercore/e/372847"
                target="_blank"
                rel="noopener noreferrer"
                className={`
                  bmc-button-wrapper 
                  ${
                    !isSizeSelected
                      ? styles.disabledButton
                      : styles.enabledButton
                  }
                `}
              >
                <img
                  src="/assets/bmc.png"
                  alt="Buy Me A Coffee"
                  className="bmc-button-img"
                />
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
};

export default Main;
