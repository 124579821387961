import React, { useEffect, useState } from "react";
import "./Success.css";
import { FaDownload } from "react-icons/fa";

const Success = () => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    const storedTokens = JSON.parse(localStorage.getItem("validTokens")) || [];
    if (storedTokens.includes(token)) {
      setIsValid(true);

      const updatedTokens = storedTokens.filter((t) => t !== token);
      localStorage.setItem("validTokens", JSON.stringify(updatedTokens));
    } else {
      setIsValid(false);
    }
  }, []);

  if (!isValid) {
    return (
      <div className="invalid-container">
        <h1>Access Denied</h1>
        <p>You do not have permission to view this page.</p>
      </div>
    );
  }

  return (
    <div className="success-container">
      <h1>Payment Successful!</h1>
      <p>
        Thank you for your purchase. Click the button below to download your
        file!
      </p>
      <a href="/files/jittercore-helper-v3.pdf" download className="download-pdf-button">
        <FaDownload className="downIcon" /> Download
      </a>
    </div>
  );
};

export default Success;
