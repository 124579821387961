// SlickButton.jsx
import React, { useState, useEffect } from "react";
import "./SlickButton.css";

const paymentConfig = {
  advice: {
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    crypto: {
      currency: "USD",
      amountf: "24.00",
      item_name: "Content Advice",
      item_desc: "One hour video call with Michael.",
      success_url: "https://jittercore.art/success?token=advice",
      cancel_url: "https://jittercore.art/cancel",
    },
    bmcLink: "https://buymeacoffee.com/jittercore/e/350735",
  },
  "close-friends": {
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    crypto: {
      currency: "USD",
      amountf: "49.00",
      item_name: "Lifetime Instagram Following",
      item_desc: "Michael will follow you on Instagram (@jittercore)",
      success_url: "https://jittercore.art/success?token=close",
      cancel_url: "https://jittercore.art/cancel",
    },
    bmcLink: "https://buymeacoffee.com/jittercore/e/371791",
  },
  "all-prompts": {
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    crypto: {
      currency: "USD",
      amountf: "49.00",
      item_name: "All prompts in one",
      item_desc:
        "All available prompts by @jittercore from this section, as well as access to regularly updated prompts without payment.",
      success_url: "https://jittercore.art/success?token=all-prompts",
      cancel_url: "https://jittercore.art/cancel",
    },
    bmcLink: "https://buymeacoffee.com/jittercore/e/373192",
  },
};

const SlickButton = () => {
  const [activeSection, setActiveSection] = useState("advice");
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const services = ["advice", "close-friends", "all-prompts"];

    const handleScroll = () => {
      const separators = document.querySelectorAll(".separator");
      let currentIndex = -1;
      separators.forEach((separator, index) => {
        const rect = separator.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2) {
          currentIndex = index;
        }
      });
      const newActiveSection =
        currentIndex === -1
          ? services[0]
          : services[currentIndex % services.length];

      setActiveSection(newActiveSection);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (!isVisible) return null;

  const config = paymentConfig[activeSection];
  const token = btoa(`${activeSection}-${Date.now()}`);

  const getMainText = () => {
    switch (activeSection) {
      case "advice":
        return "Content advice?";
      case "close-friends":
        return "I'll follow you on IG";
      case "all-prompts":
        return "All Prompts for $49";
      default:
        return "Service?";
    }
  };

  return (
    <div className={`slick-button-container ${activeSection}`}>
      <button className="close-button" onClick={() => setIsVisible(false)}>
        ×
      </button>
      <div className="main-text">{getMainText()}</div>
      <div className="icons">
        <form
          action="https://www.coinpayments.net/index.php"
          method="post"
          target="_top"
          onSubmit={() => {
            const storedTokens =
              JSON.parse(localStorage.getItem("validTokens")) || [];
            storedTokens.push(token);
            localStorage.setItem("validTokens", JSON.stringify(storedTokens));
          }}
          className="crypto-form"
        >
          <input type="hidden" name="cmd" value="_pay_simple" />
          <input type="hidden" name="reset" value="1" />
          <input type="hidden" name="merchant" value={config.merchantId} />
          <input type="hidden" name="currency" value={config.crypto.currency} />
          <input type="hidden" name="amountf" value={config.crypto.amountf} />
          <input
            type="hidden"
            name="item_name"
            value={config.crypto.item_name}
          />
          <input
            type="hidden"
            name="item_desc"
            value={config.crypto.item_desc}
          />
          <input
            type="hidden"
            name="success_url"
            value={`${config.crypto.success_url}&token=${token}`}
          />
          <input
            type="hidden"
            name="cancel_url"
            value={config.crypto.cancel_url}
          />
          <input type="hidden" name="custom" value={token} />
          <button type="submit" className="slick-button2">
            Crypto
          </button>
        </form>
        <a href={config.bmcLink} target="_blank" rel="noopener noreferrer">
          <img
            src="/assets/bmc-logo.png"
            alt="Buy Me A Coffee"
            className="icon"
          />
        </a>
      </div>
    </div>
  );
};

export default SlickButton;
