// src/components/BuyButton.jsx
import React from "react";
import "./BuyButton.css";

const BuyButton = () => {
  const handleClick = () => {
    const academySection = document.getElementById("academy");
    const headerHeight = document.querySelector(".header").offsetHeight;

    if (academySection) {
      const targetPosition =
        academySection.getBoundingClientRect().top +
        window.pageYOffset -
        headerHeight;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });

      setTimeout(() => {
        const oldPrices = document.querySelectorAll(".old-price");
        oldPrices.forEach((price) => {
          price.classList.add("animate-discount");
          setTimeout(() => price.classList.remove("animate-discount"), 1000);
        });
      }, 1000);
    }
  };

  const snowflakeCount = 20;
  const snowflakes = Array.from({ length: snowflakeCount }, (_, i) => (
    <span key={i} className="snowflake" />
  ));

  return (
    <button className="buy-button" onClick={handleClick}>
      {snowflakes}
      DISCOUNT: <span style={{ color: "green" }}>JITTER10</span>
    </button>
  );
};

export default BuyButton;
