import React, { useState, useEffect } from "react";
import { FaCheck, FaQuestionCircle } from "react-icons/fa";
import "./Courses.css";
import { motion, AnimatePresence } from "framer-motion";

const plans = [
  {
    name: "Base",
    price: 35.0,
    discount: 10,
    descriptionShort: "Quick start for beginners.",
    benefitsShort: [
      "Quick-start PDF",
      "AI-video basics",
      "Creation guide",
      "Basic editing",
      "Easy implementation",
    ],
    fullInfo: `
- Quick-start PDF course covering AI-video basics.
- Step-by-step guide to video creation and basic editing.

Expected results: 10K followers & 10M views.
    `,
    type: "base",
    currency: "USD",
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    bmcLink: "https://buymeacoffee.com/jittercore/e/349981",
  },
  {
    name: "Premium",
    price: 69.0,
    discount: 10,
    descriptionShort: "Entrepreneur training.",
    benefitsShort: [
      "Complete training",
      "Prompt mastery",
      "Advanced tools",
      "Promotion tactics",
      "Private mentoring",
    ],
    fullInfo: `
- Complete AI-video training with effective prompt mastery.
- In-depth instruction on advanced tools and promotion tactics.
- Mentorship for personalized guidance.

Expected results: 25K followers & 45M views.
    `,
    type: "premium",
    currency: "USD",
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    bmcLink: "https://buymeacoffee.com/jittercore/e/349984",
  },
  {
    name: "Advantage",
    price: 149.0,
    discount: 10,
    descriptionShort: "Full access with exclusive bonuses.",
    benefitsShort: [
      "Complete training",
      "Prompt mastery",
      "Advanced tools",
      "Private mentoring",
      "Instagram follow & chat",
    ],
    fullInfo: `
- Complete AI-video training with all Premium benefits.
- Mentorship for personalized guidance.

Expected results: 100K followers & 125M views.
Bonus: Instagram follow from Michael & Exclusive chat access.
    `,
    type: "advantage",
    currency: "USD",
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    bmcLink: "https://buymeacoffee.com/jittercore/e/349985",
  },
];

const Courses = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const generateToken = (planName) => {
    return btoa(`${planName}-${Date.now()}-${Math.random()}`);
  };

  const openModal = (plan) => {
    setSelectedPlan(plan);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setSelectedPlan(null);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    if (selectedPlan) {
      window.addEventListener("keydown", handleEsc);
    }
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [selectedPlan]);

  return (
    <section id="academy" className="py-10 px-4">
      <h2 className="text-3xl font-bold text-center mb-20 text-white font-poppins-bold">
        The Academy
      </h2>
      <div className="flex flex-wrap justify-center gap-10">
        {plans.map((plan, index) => {
          const oldPrice = (plan.price * 1.2).toFixed(2);
          const discountedPrice = plan.price.toFixed(0);
          const token = generateToken(plan.name);

          return (
            <div
              key={index}
              className="courses-plan-card relative w-72 rounded-xl p-6 bg-white/10 backdrop-blur-md shadow-md text-white flex flex-col gap-4 z-10 transform transition-transform duration-300 hover:scale-105"
            >
              {plan.type === "premium" && (
                <div className="courses-plan-popular-label absolute top-4 right-4 bg-white text-black text-xs font-bold py-1 px-2 rounded-xl">
                  Popular
                </div>
              )}
              <div className="courses-plan-header flex items-center justify-between">
                <div className="courses-plan-title-container flex items-center gap-2">
                  <h3 className="courses-plan-name text-xl font-medium font-poppins-medium">
                    {plan.name}
                  </h3>
                  <FaQuestionCircle
                    className="courses-plan-info-icon text-gray-400 cursor-pointer"
                    onClick={() => openModal(plan)}
                  />
                </div>
              </div>
              <div className="price-section">
                <span className="new-price">
                  <span className="dollar-sign">$</span>
                  {discountedPrice}
                </span>
                <span className="old-price">
                  <span className="dollar-sign">$</span>
                  {oldPrice}
                </span>
              </div>
              <p className="text-sm text-gray-300 whitespace-pre-line font-poppins-light">
                {plan.descriptionShort}
              </p>
              <ul className="flex flex-col gap-2">
                {plan.benefitsShort.map((benefit, i) => (
                  <li key={i} className="flex items-center gap-2">
                    <FaCheck className="text-green-500" />
                    <span className="font-poppins-medium">{benefit}</span>
                  </li>
                ))}
              </ul>
              <div className="mt-6 flex flex-row items-center justify-center gap-2">
                <form
                  action="https://www.coinpayments.net/index.php"
                  method="post"
                  target="_top"
                  onSubmit={() => {
                    const storedTokens =
                      JSON.parse(localStorage.getItem("validTokens")) || [];
                    storedTokens.push(token);
                    localStorage.setItem(
                      "validTokens",
                      JSON.stringify(storedTokens)
                    );
                  }}
                  className="flex justify-center"
                >
                  <input type="hidden" name="cmd" value="_pay_simple" />
                  <input type="hidden" name="reset" value="1" />
                  <input
                    type="hidden"
                    name="merchant"
                    value={plan.merchantId}
                  />
                  <input type="hidden" name="currency" value={plan.currency} />
                  <input type="hidden" name="amountf" value={discountedPrice} />
                  <input type="hidden" name="item_name" value={plan.name} />
                  <input
                    type="hidden"
                    name="item_desc"
                    value={plan.descriptionShort}
                  />
                  <input
                    type="hidden"
                    name="success_url"
                    value="https://limewire.com/?referrer=pq7i8xx7p2"
                  />
                  <input
                    type="hidden"
                    name="cancel_url"
                    value="https://jittercore.art/cancel"
                  />
                  <input type="hidden" name="custom" value={token} />
                  <button
                    type="submit"
                    className="bg-white text-gray-900 font-semibold py-2.5 px-4 rounded-lg hover:bg-gray-300 transition-colors duration-300 flex items-center justify-center font-poppins-medium"
                  >
                    Buy with crypto
                  </button>
                </form>
                <a
                  href={plan.bmcLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bmc-button-wrapper"
                >
                  <img
                    src="/assets/bmc.png"
                    alt="Buy Me A Coffee"
                    className="bmc-button-img"
                  />
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-12 text-center text-white font-poppins-light px-4">
        <p className="explanation-text">
          Note: Once you purchase a plan, an additional window will open with a
          link to download the materials. Depending on your plan, you will also
          gain access to extra features such as direct communication with
          participants and mentors, including guidance from Michael.
        </p>
      </div>

      <AnimatePresence>
        {selectedPlan && (
          <motion.div
            className="unique-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <motion.div
              className="unique-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="courses-modal-title">{selectedPlan.name}</h3>

              {/* БЛОК, КОТОРЫЙ МЕНЯЕМ ДЛЯ КАЖДОГО ПУНКТА С ИКОНОЙ */}
              <div className="courses-modal-description-centered">
                {selectedPlan.fullInfo.split("\n").map((line, index) => {
                  const trimmed = line.trim();

                  // Если строка пустая — даём просто перенос
                  if (!trimmed) {
                    return <br key={index} />;
                  }

                  // Если начинается с дефиса, рендерим иконку + текст
                  if (trimmed.startsWith("-")) {
                    // Убираем сам дефис и пробел
                    const text = trimmed.slice(1).trim();
                    return (
                      <div
                        key={index}
                        className="flex items-center justify-center my-2"
                      >
                        <FaCheck className="text-green-500 mr-3" />
                        <span>{text}</span>
                      </div>
                    );
                  }

                  // Иначе обычный абзац
                  return (
                    <p key={index} className="my-2 text-center">
                      {trimmed}
                    </p>
                  );
                })}
              </div>
              {/* КОНЕЦ БЛОКА */}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Courses;
